import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\projects\\vidyalaya_lms_website\\Components\\Common\\Header.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\projects\\vidyalaya_lms_website\\Components\\ScrollToTop.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\projects\\vidyalaya_lms_website\\Context\\CommonAppScript.js");
;
import(/* webpackMode: "eager", webpackExports: ["PageState"] */ "D:\\projects\\vidyalaya_lms_website\\Context\\PageState.js");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\styles\\globle.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\styles\\Loader.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\styles\\Footer.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\vidyalaya_lms_website\\Ui_Elements\\css\\Button_Common.module.scss");
