"use client";
import Button_Common from "@/Ui_Elements/Button_Common";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import style from "@/styles/Header.module.scss";
import { usePathname } from "next/navigation";

const menuData = [
    {
      title: "Home",
      link: "/",
      submenu: []
    },
    {
      title: "Platform",
      submenuclass:"platformsubmenu",
      link: "#",
      submenu: [
        { title: "Overview", link: "/overview" },
        { title: "Features", link: "/features" },
        { title: "Integrations", link: "/integrations" },
        { title: "LMS Mobile App", link: "/mobile-app" },
        { title: "White Labelling", link: "#" },
      ]
    },
    {
      title: "Solutions",
      submenuclass:"solutionssubmenu",
      link: "#",
      submenu: [
        { title: "Use Cases", link: "#" },
        { title: "Industry", link: "#" },
        { title: "Employee Training", link: "#" },
        { title: "Education", link: "#" },
        { title: "Sales Training", link: "#" },
        { title: "Higher Education", link: "#" },
        { title: "Security Training", link: "#" },
        { title: "Vocational Centers", link: "#" },
        { title: "Onboarding Training", link: "#" },
        { title: "Universities", link: "#" },
        { title: "Software", link: "#" },
        { title: "HealthCare", link: "#" }
      ]
    },
    {
      title: "Resources",
      submenuclass:"resourcessubmenu",
      link: "#",
      submenu: [
        { title: "Blogs", link: "/blog" },
        { title: "Ebook", link: "#" },
        { title: "Webinar", link: "#" },
        { title: "Case Study", link: "#" },
        { title: "Clients", link: "#" }
      ]
    },
    {
      title: "Company",
      submenuclass:"companysubmenu",
      link: "#",
      submenu: [
        { title: "About Us", link: "/about-us" },
        { title: "Why Vidyalaya LMS", link: "/why-vidyalaya" },
        { title: "Contact Us", link: "/contact-us" }
      ]
    }
  ];

function Header() {
  const [isActive, setIsActive] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [activeMenu, setActiveMenu] = useState(null);
  const pathname = usePathname();
  const handleToggle = () => {
    setIsMenuVisible(!isMenuVisible);
    setIsActive(!isActive);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (menuId) => {
    if (!isMobile) {
      setActiveMenu(menuId); // Set the active menu on hover
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setActiveMenu(null); // Reset the active menu on mouse leave
    }
  };

  const handleMobileClick = (e, menuId) => {
    if (isMobile) {
      e.preventDefault(); // Prevent default link action on mobile
      setActiveMenu(activeMenu === menuId ? null : menuId); // Toggle active menu on click for mobile
    }
  };

  const handleBackClick = () => {
    setActiveMenu(null); // Reset the active menu on back click (for mobile)
  };

  const closeSidebar = () => {
    setIsMenuVisible(false);
    setIsActive(false);
    setActiveMenu(null); // Also close any open submenu
  };

  useEffect(() => {
    closeSidebar();
  }, [pathname])
  

  return (
    <header className={style["headertag"]}>
    <div className={`${style["section-containe"]} container`}>
      <div className={`${style["headermaindiv"]} ${isActive ? style["active"] : ""}`}>
        <Link href="/" className={`${style["websitelogobox"]}`}>
          <Image className={`${style["headerlogo"]}`} src="/images/vid_lms_logo.png" alt="Vidyalaya LMS" width="200" height="0" />
        </Link>
        <div className={`${style["overlaymenu"]}`} onClick={closeSidebar}></div>

        <div className={`${style["mainboxheader"]}`} style={{ display: isMenuVisible ? "none" : "flex" }}>
          <nav className={`${style["headernavbar"]}`}>
          <ul className={style.navmenumaindiv}>
      {menuData.map((menu, index) => (
        <li
          key={index}
          className={`${style["singlemenu"]} ${activeMenu === index ? style["active"] : ""}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <Link href={menu.link} className={style["menulinks"]} onClick={(e) => handleMobileClick(e, index)}>
            {menu.title}
            {menu.submenu.length > 0 && (
              <Image className={style["downarrow"]} src="/images/down_arrow.svg" alt={menu.title} width={0} height={0} />
            )}
          </Link>
          {menu.submenu.length > 0 && (
            <ul className={`${style["submenubox"]} ${style[menu.submenuclass]} ${activeMenu === index ? style["show"] : ""}`} suppressHydrationWarning={true}>
                <li>
                  <a href="#" onClick={handleBackClick}>
                    <img className={style["headerlogo"]} src="/images/down_arrow.svg" alt="Back" /> Back
                  </a>
                </li>
              {menu.submenu.map((subItem, subIndex) => (
                <li key={subIndex} onClick={closeSidebar}>
                  <Link href={subItem.link}>{subItem.title}</Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
          </nav>
          <div className={`${style["rightloginpart"]}`}>
            <Link href="#" className={`${style["loginmenu"]}`}>
              Log in
            </Link>
            <Button_Common text={`Request For Demo`} href="/contact-us" />
          </div>
        </div>

        <div className={`${style["mobiletogglemenu"]} ${isActive ? style["active"] : ""}`} onClick={handleToggle}>
          <div className={`${style["menubarbox"]}`}>
            <span className={`${style["togglebar"]}`}></span>
            <span className={`${style["togglebar"]}`}></span>
            <span className={`${style["togglebar"]}`}></span>
          </div>
        </div>
      </div>
    </div>
  </header>
  );
}

export default Header;
